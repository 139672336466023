import React, { useState } from 'react'
import { useStaticQuery, graphql, Link } from 'gatsby'
import styled, { css } from 'styled-components'
import { useWindowScroll, useWindowSize, useMount } from 'react-use'

import * as Scroll from 'react-scroll'
let ScrollLink = Scroll.Link
let Element = Scroll.Element
let Events = Scroll.Events
let scroll = Scroll.animateScroll
let scrollSpy = Scroll.scrollSpy
let scroller = Scroll.scroller

import { Layout, Section, Block } from '../components'
import { media } from '../styles/utils'
import {
    container,
    padding,
    colours,
    type,
    bgIcon,
    bgImage,
    buttonSmall,
    button,
} from '../styles/global'
import { parseACF, getImageCrop } from '../utils'
import { render } from 'react-dom'
import ProgressiveImage from 'react-progressive-image'
import { motion, transform } from 'framer-motion'

const Team = (props) => {
    const { x, y } = useWindowScroll()
    const { width, height } = useWindowSize()
    const [activeIndex, setActiveIndex] = useState(-1)
    const [truncate, setTruncate] = useState(true)

    const data = parseACF(useStaticQuery(query), 'allWordpressInfopages')

    // Scroll-to Easing

    const handleClick = (e, to) => {
        e.preventDefault()
        e.stopPropagation()

        scroller.scrollTo(to, {
            duration: 1500,
            smooth: 'easeInOutCubic',
        })
    }

    // Intro

    const renderIntro = () => {
        return (
            <Intro>
                <Title>Team</Title>
                <Text dangerouslySetInnerHTML={{ __html: data.introduction }} />
            </Intro>
        )
    }

    // Jump Nav

    const renderJumpNav = () => {
        const items =
            data.classes &&
            data.classes.map((item, i) => {
                return (
                    <ScrollLink
                        key={i}
                        to={item.type.slug}
                        spy={true}
                        duration={500}
                        className="list-item"
                        activeClass="active"
                        onClick={(e) => handleClick(e, item.type.slug)}
                    >
                        {item.type.title}
                    </ScrollLink>
                )
            })

        return (
            <List>
                <Text>Jump to:</Text>
                {items}
            </List>
        )
    }

    // Team

    const renderTeam = () => {
        if (!data.team) return

        const items = data.team.map((item, i) => {
            return (
                <ListItem key={i} active={activeIndex == i}>
                    <ImageWrapper>
                        <Image
                            key={item.acf.image?.sizes?.medium2}
                            src={item.acf.image?.sizes?.medium2}
                        >
                            {(src, loading) => {
                                return (
                                    <BGImage
                                        image={src}
                                        style={{ opacity: loading ? 0 : 1 }}
                                    />
                                )
                            }}
                        </Image>
                    </ImageWrapper>

                    <Meta>
                        <Heading>{item.title}</Heading>
                        <SubHeading
                            dangerouslySetInnerHTML={{ __html: item.acf.intro }}
                        />

                        {activeIndex != i && (
                            <>
                                {item.acf.short_description && (
                                    <ShortDescription
                                        dangerouslySetInnerHTML={{
                                            __html: item.acf.short_description,
                                        }}
                                    />
                                )}

                                {item.acf.expanded_description && (
                                    <Button
                                        onClick={() => {
                                            setActiveIndex(i)
                                        }}
                                    >
                                        Read More
                                    </Button>
                                )}
                            </>
                        )}

                        {activeIndex == i && (
                            <>
                                <LongDescription
                                    dangerouslySetInnerHTML={{
                                        __html: item.acf.expanded_description,
                                    }}
                                />

                                <Button
                                    onClick={() => {
                                        setActiveIndex(null)
                                    }}
                                >
                                    Read less
                                </Button>
                            </>
                        )}
                    </Meta>
                </ListItem>
            )
        })

        return <List>{items}</List>
    }

    return (
        <Layout meta={data && data.seo}>
            <Container>
                {renderIntro()}
                {renderTeam()}
            </Container>
        </Layout>
    )
}

// Shared

const Title = styled.h1``
const Heading = styled.div``
const SubHeading = styled.div``
const Wrap = styled.div``

const Text = styled.div``
const ShortDescription = styled.div``
const LongDescription = styled.div``
const Meta = styled.div``
const Button = styled.div``

// Utlity

const Image = styled(ProgressiveImage)`
    overflow: hidden;
`
const BGImage = styled.div`
    background-image: url(${(props) => props.image});
    ${bgImage};
    transition: opacity 0.45s ease;
    filter: grayscale(100%) brightness(1);
`

// Layout

const Container = styled.div`
    ${container}
    ${padding}
	flex-direction: column;
    Margin-top: 200px;
`

// Intro

const Intro = styled.div`
    margin-bottom: 80px;
    display: flex;
    flex-direction: column;

    ${media.tablet`
		margin-bottom: 20px;	
	`}

    ${Title} {
        ${type.heading1};
        padding-bottom: 32px;
    }

    ${Text} {
        ${type.body};
        max-width: 80%;

        ${media.tablet`
			max-width: 100%;
		`}
    }
`

// List

const List = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-end;
`

const ImageWrapper = styled.div`
    position: relative;
    overflow: hidden;
    flex-basis: 50%;
    min-width: 280px;
    height: 238px;
    max-width: 280px;
    border-radius: 22px;

    &::after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background: #F6A73C;
        mix-blend-mode: screen;
    }

    ${media.tablet`
        flex-basis: 100%;
        width: 168px;
        min-height: 142px;
        min-width: auto;
        margin-bottom: 20px;
    `}
`

const ListItem = styled.div`
    width: 100%;
    max-width: 915px;

    display: flex;
    background: ${colours.lightBeige};
    transition: 0.6s cubic-bezier(0.25, 0.46, 0.45, 0.94);
    border-radius: 22px;

    padding: 38px;
    box-sizing: border-box;
    margin-bottom: 20px;

    ${media.tablet`
		flex-direction: column;	
		padding: 20px;
	`}

    ${(props) => {
        if (props.active == true)
            return css`
                background: ${colours.lightBlue};
            `
    }}

    &:hover {
        background: ${colours.lightBlue};
    }

    ${BGImage} {
        width: 100%;
        height: 100%;
    }

    ${Meta} {
        flex-basis: auto;
        padding-left: 30px;

        ${media.tablet`
			width: 100%;
			padding-left: 0;
		`}

        ${Heading} {
            ${type.heading3};
            border-bottom: 3px solid ${colours.black};
            padding-bottom: 20px;
        }

        ${SubHeading} {
            font-size: 21px;
            padding: 20px 0;
        }

        ${ShortDescription}, ${LongDescription} {
            p {
                ${type.bodySmall};

                a {
                    ${button};
                    cursor: pointer;
                    border: 2px solid;
                    padding: 12px 16px;
                    font-size: 20px;

                    &:before {
                        width: 8px;
                        height: 20px;
                    }
                }
            }
        }

        ${Button} {
            ${type.bodySmall};
            text-decoration: underline;
            cursor: pointer;
        }
    }
`

// Data

export const query = graphql`
    query {
        allWordpressInfopages(filter: { slug: { eq: "team" } }) {
            nodes {
                acf_json
                title
            }
        }
    }
`

export default Team
